import './styles.css';

function PageContent() {
    return (
        <div className='flex center'>
            <h1 className='procura'>
                O que você procura?
            </h1>

            <a href='/athome'>
                <img
                    className='botao'
                    src="assets/Aulas em casa.png"
                    alt="Aulas em Casa"
                    href="index.html"
                />
            </a>

            <a href='/atschool'>
                <img
                    className="botao"
                    src="assets/Aulas na escola.png"
                    alt="Aulas na escola"
                    href="index.html"
                />
            </a>

        </div>
    );
}

export default PageContent;