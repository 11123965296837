import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import HomeTemp from './pages/home-temp';
import Houses from './pages/houses/houses-index';
import Schools from './pages/schools/schools-index';
import 'typeface-delius';
import 'typeface-poppins';
import { createGlobalStyle } from 'styled-components';


const GlobalStyles = createGlobalStyle`
  body {
    font-family: 'Poppins', sans-serif;
    /* Outros estilos globais */
  }
`;

function App() {
  return (

    
    <Router>
      <GlobalStyles />

      <Routes>
      <Route path="/" element={<HomeTemp />} />
        <Route path="/home" element={<Home />} />
        <Route path="/athome" element={<Houses />} />
        <Route path="/atschool" element={<Schools />} />
        {/* Defina outras rotas aqui */}
      </Routes>
    </Router>
  );
}

export default App;
