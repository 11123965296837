import './styles.css';

const texto = encodeURIComponent("Olá, gostaria de mais informações sobre aulas de inglês!");
const numero = "5541999102392";
const linkWhatsapp = `https://wa.me/${numero}?text=${texto}`;
const linkInstagram = `https://www.instagram.com/treehouse.english?igsh=NmYxdnc4MGR6Z2lz`

function Contact() {
    return (
        

        <div className='flex column justify-center'>
            <h1 className='title fale'>
                Fale com a gente:
            </h1>

            <div className='flex line justify-around'>
                <div className='flex'>
                    <a href={linkWhatsapp} className='orange-btn' target="_blank" rel="noopener noreferrer">
                        Whatsapp
                    </a>
                    
                </div>

                <div className='flex'>
                    <a href={linkInstagram} className='orange-btn' target="_blank" rel="noopener noreferrer">
                        Instagram
                    </a>
                </div>
            </div>
        </div>
    );
}
export default Contact;