import React from 'react';
import NavMenu from '../../components/nav-menu/NavMenu';
import Banner from '../../components/banner/Banner';
import PageContent from '../../components/page-content/page-content';
import Contact from '../../components/contact/contact';
import Footer from '../../components/footer/footer';


function Schools() {
  return (
    <container>
      <NavMenu />
      <Banner />
      <PageContent />
      <Contact />
      <Footer />

    </container>
  );
}

export default Schools;