import React from 'react';
import Botao from '../../components/orange-btn/btn';
import './styles.css';

const texto = encodeURIComponent("Olá, gostaria de mais informações sobre aulas de inglês!");
const numero = "5541999102392";
const linkWhatsapp = `https://wa.me/${numero}?text=${texto}`;
const linkInstagram = `https://www.instagram.com/treehouse.english?igsh=NmYxdnc4MGR6Z2lz`

function HomeTemp() {
  return (
    <container>
      <div className='background flex container'>
        <div className='container flex maxwidth'>
          <img
            className='logo'
            src="assets/Logo.png"
            alt="Logo TreeHouse"
            href="index.html"
          />

          <h1 className='manutencao'>
            Nosso site está sob manutenção, logo traremos novidades!
          </h1>
          <p className='manutencao'>
            Enquanto isso, acesso nossas redes ou nos contate no Whatsapp:
          </p>
        </div>

        <div className='justify-around maxwidth'>
          <Botao texto="WhatsApp" link={linkWhatsapp} />
          <Botao texto="Instagram" link={linkInstagram} />
        </div>
      </div>
    </container>
  );
}

export default HomeTemp;