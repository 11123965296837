import './styles.css';

function Footer() {
    return (
        <div className="height footer-yellow-gradient">

        </div>    
    );
}

export default Footer;
