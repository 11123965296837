import './styles.css';


function Banner() {
    return (
        <img
        id="banner"
        src="assets/Header Image.png"
        alt="Logo TreeHouse"
        href="index.html"
    />
    );
}

export default Banner;