
import React from 'react';
import './styles.css';

const Botao = ({ texto, link }) => {
    return (
      <a target="_blank" rel="noreferrer" href={link} className="orange-btn" >
        {texto}
      </a>
    );
  };
  
export default Botao;

