import './styles.css';

function NavMenu() {
    return (
        <nav className="nav-menu flex-row center yellow-gradient">
            {/* Corrigir este link que deverá abrir o menu lateral */}
            <a href="index.html"
            >
                <img
                    id="menu"
                    src="assets/menu.png"
                    alt="Botao de Menu"
                    href="index.html"
                />
            </a>

            <a>
                <img
                    id="logo"
                    src="assets/Logo.png"
                    alt="Logo TreeHouse"
                    href="index.html"
                />
            </a>
        </nav>
    );
}

export default NavMenu;
